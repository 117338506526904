import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Entity } from '@backstage/catalog-model'

const PodAdditionalInfoFields = ({ entity }: { entity: Entity }) => {
  const annotations = entity?.metadata.annotations
  const opsgenieHandler = annotations?.['sennder.com/opsgenie-handler']
  const monitorAlertChannel = annotations?.['sennder.com/monitor-alert-channel']

  return (
    <>
      <Box sx={{ mt: 3 }}>
        {opsgenieHandler ? (
          <Typography variant="body1">{opsgenieHandler}</Typography>
        ) : (
          <Typography variant="body1">N/A</Typography>
        )}

        <Typography variant="body2" color="textSecondary">
          OpsGenie
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        {monitorAlertChannel ? (
          <Typography variant="body1">{monitorAlertChannel}</Typography>
        ) : (
          <Typography variant="body1">N/A</Typography>
        )}

        <Typography variant="body2" color="textSecondary">
          Production Monitor Alert Channel
        </Typography>
      </Box>
    </>
  )
}

export default PodAdditionalInfoFields
